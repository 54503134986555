import React from 'react'
import Link from '../Router/Link'
import Route from '../Router/Route'
import './Header.css'

const Header = () => {
  return (
    <header className="Header">
      <Route href={/^\/plant\/[a-z0-9-]+$/}>
        <span className="List-back">
          <Link href="/">&#8592; Revenir à la liste</Link>
        </span>
      </Route>
      <Link href="/">
        <h1>Consociatio</h1>
      </Link>
    </header>
  )
}

export default Header
