import { useState } from 'react'
import { v4 as uuid } from 'uuid'
import defaultPlants from './data/plants.json'
import defaultAssociations from './data/associations.json'
import { findAssociation } from './findAssociation'
import { Association, Plants } from './types'

export type IncrementAssociation = (plant: Plants, withPlant: Plants) => void

type UsePlantsList = () => [
  Array<Plants>,
  { addPlant: (name: string) => void; removePlant: (id: string) => void },
  Array<Association>,
  { incrementAssociation: IncrementAssociation },
]

const usePlantsList: UsePlantsList = () => {
  const [plants, setPlants] = useState<Array<Plants>>(defaultPlants)
  const [associations, setAssociations] = useState<Array<Association>>(
    defaultAssociations as Array<Association>,
  )

  const addPlant = (name: string) => {
    setPlants([...plants, { id: uuid(), name }])
  }

  const removePlant = (id: string) => {
    setPlants(plants.filter((p) => p.id !== id))
  }

  const incrementAssociation = (plant: Plants, withPlant: Plants) => {
    const association = findAssociation(associations)(plant, withPlant)

    if (association) {
      const oldType = association.type

      const toType = () => {
        switch (oldType) {
          case 'bad':
            return 'neutral'
          case 'good':
            return 'bad'
          case 'neutral':
          default:
            return 'good'
        }
      }

      setAssociations(
        associations.map((a) =>
          a.id === association.id ? { ...a, type: toType() } : a,
        ),
      )
    } else {
      setAssociations([
        ...associations,
        { id: uuid(), plant: plant.id, withPlant: withPlant.id, type: 'good' },
      ])
    }
  }

  return [
    plants,
    { addPlant, removePlant },
    associations,
    { incrementAssociation },
  ]
}

export default usePlantsList
