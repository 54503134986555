import { Association, Plants } from './types'

export const findAssociation = (associations: Array<Association>) => (
  plant: Plants,
  withPlant: Plants,
) =>
  associations.find(
    (a) =>
      (a.plant === plant.id && a.withPlant === withPlant.id) ||
      (a.withPlant === plant.id && a.plant === withPlant.id),
  )
