import { useRef, useState, useEffect } from 'react'

const useComponentHeigth = (): [number, React.RefObject<HTMLDivElement>] => {
  const ref = useRef<HTMLDivElement>(null)
  const [height, setHeight] = useState(0)

  useEffect(() => {
    const container = ref.current
    if (container) {
      setHeight(container.clientHeight)

      const handleResize = () => {
        if (container) {
          setHeight(container.clientHeight)
        }
      }
      container.addEventListener('resize', handleResize)

      return () => {
        if (container) {
          container.removeEventListener('resize', handleResize)
        }
      }
    }
  }, [ref, setHeight])

  return [height, ref]
}

export default useComponentHeigth
