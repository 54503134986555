import { useEffect, useState } from 'react'

export const HISTORY_UPDATE = 'historyUpdate'

function customLocation(location: Location) {
  return {
    pathname: location.pathname,
  }
}

export default function useLocation() {
  const [location, setLocation] = useState(customLocation(window.location))

  useEffect(() => {
    function onUrlChange() {
      setLocation(customLocation(window.location))
    }

    window.addEventListener('popstate', onUrlChange)
    window.addEventListener(HISTORY_UPDATE, onUrlChange)

    return () => {
      window.removeEventListener('popstate', onUrlChange)
      window.removeEventListener(HISTORY_UPDATE, onUrlChange)
    }
  }, [setLocation])

  return location
}
