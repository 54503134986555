import React, { MouseEvent, ReactElement } from 'react'
import { push } from './Route'

interface Props {
  children: ReactElement | string
  href: string
}

export default function Link({ children, href }: Props) {
  function handleClick(e: MouseEvent<HTMLAnchorElement>) {
    e.stopPropagation()
    e.preventDefault()
    push(href)
  }
  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a style={{ cursor: 'pointer' }} onClick={handleClick}>
      {children}
    </a>
  )
}
