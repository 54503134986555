import React from 'react'
import './List.css'
import { push } from '../Router/Route'
import { Plants } from '../types'

interface Props {
  plants: Array<Plants>
}

const List = ({ plants }: Props) => {
  function handleClick(plant: Plants) {
    push(`/plant/${plant.id}`)
  }
  return (
    <div className="List">
      {plants.map((p) => (
        <div
          key={p.id}
          className="Row"
          // @ts-ignore
          onClick={() => handleClick(p)}
        >
          <h1>{p.name}</h1>
        </div>
      ))}
    </div>
  )
}

export default List
