import { ReactElement } from 'react'
import useLocation, { HISTORY_UPDATE } from './useLocation'

interface Props {
  children: ReactElement
  href: RegExp
}

export function push(newPath: string) {
  window.history.pushState(undefined, 'Consociatio', newPath)
  const event = new CustomEvent(HISTORY_UPDATE)
  window.dispatchEvent(event)
}

export default function Route({ children, href }: Props) {
  const location = useLocation()
  const display = location.pathname.match(href)

  if (display) return children
  return null
}
