import React, { useState, FormEvent } from 'react'
import './App.css'

import usePlantsList from './usePlantsList'
import Header from './Header'
import useComponentHeigth from './useComponentHeigth'
import List from './List'
import Route from './Router/Route'
import Plant from './Plant'

export type PlantAssociationType = 'all' | 'possible' | 'cool' | 'notcool'

function App() {
  const [headerHeight, headerRef] = useComponentHeigth()

  const [plants, { addPlant }, associations] = usePlantsList()

  const [newPlantName, setNewPlantName] = useState('')
  const [filter, setFilter] = useState('')

  const [statusLocked, setStatusLocked] = useState(true)
  const [displayDev] = useState(false)

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    e.stopPropagation()

    addPlant(newPlantName)

    setNewPlantName('')
  }

  return (
    <div className="App">
      <div ref={headerRef}>
        <Header />
        {displayDev && (
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              value={newPlantName}
              onChange={(e) => setNewPlantName(e.target.value)}
            />
            <input
              type="submit"
              disabled={newPlantName.length === 0}
              value="Ajouter"
            />
            <details>
              <summary>Plants as data</summary>
              <pre>{JSON.stringify(plants, null, 2)}</pre>
            </details>
            <details>
              <summary>Associations as data</summary>
              <pre>{JSON.stringify(associations, null, 2)}</pre>
            </details>
            <button onClick={() => setStatusLocked(!statusLocked)}>
              {statusLocked ? 'unlock' : 'lock'}
            </button>
          </form>
        )}
        <Route href={/^\/$/}>
          <form
            className="Home-filter"
            onSubmit={(e) => {
              e.preventDefault()
              e.stopPropagation()
            }}
          >
            <input
              type="text"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
              placeholder="filter"
            />
          </form>
        </Route>
      </div>
      <div
        className="Data"
        style={{ height: `calc(100% - ${headerHeight}px)`, width: '100vw' }}
      >
        <Route href={/^\/plant\/[a-z0-9-]+$/}>
          <Plant />
        </Route>
        <Route href={/^\/$/}>
          <List
            plants={plants.filter((p) =>
              p.name.toLocaleLowerCase().includes(filter.toLocaleLowerCase()),
            )}
          />
        </Route>
      </div>
    </div>
  )
}

export default App
