/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import { findAssociation } from '../findAssociation'
import useLocation from '../Router/useLocation'
import usePlant from '../usePlant'
import usePlantsList from '../usePlantsList'

import './Plant.css'

export default function Plant() {
  const location = useLocation()
  const paths = location.pathname.split('/')
  const plantId = paths[paths.length - 1]

  const plant = usePlant(plantId)
  const [plants, _, associations] = usePlantsList()

  if (!plant) return null

  return (
    <div className="Plant">
      <h2>
        <b>{plant.name}</b>, toutes les associations :
      </h2>
      <div className="Plant-List">
        {plants.map((p) => (
          <div key={p.id} className="Plant-Row">
            <h1>{p.name}</h1>
            <div className={findAssociation(associations)(plant, p)?.type} />
          </div>
        ))}
      </div>
    </div>
  )
}
